<script setup>
import { ref, reactive, onMounted, defineEmits } from 'vue'
import { helpers, required } from '@vuelidate/validators'
import { useToast } from 'primevue/usetoast'
import dayjs from 'dayjs'
import errorHandler from '@/helpers/errorhandler'
import FxReportContainer from '@/components/FxReportContainer'
import LaporanPersediaanBarangService from '@/services/LaporanPersediaanBarangService'
import PabrikService from '@/services/PabrikService'
import useVuelidate from '@vuelidate/core'
import { saveAs } from 'file-saver'

const toast = useToast()
const laporanService = new LaporanPersediaanBarangService()
const pabrikService = new PabrikService()
let dialogViewer = ref(false)
let pdfSource = ref('')
let pdfTitle = ref('PDF Viewer')
let pdfFilename = ref('')

let isLoadingExport = ref(false)
let isLoading = ref(false)
let form = reactive({
  date_from: null,
  date_to: null,
  pabrik: 0,
})
let submitted = ref(false)
let list_pabrik = ref([])

const rules = {
  pabrik: {
    required: helpers.withMessage('Pabrik harus diisi.', required),
  },
  date_from: {
    required: helpers.withMessage('Tanggal harus diisi.', required),
  },
  date_to: {
    required: helpers.withMessage('Tanggal harus diisi.', required),
  },
}

const v$ = useVuelidate(rules, form)

// eslint-disable-next-line no-unused-vars
const emit = defineEmits(['toggle'])

onMounted(async () => {
  const res = await pabrikService.get('?sort=nama')
  list_pabrik.value = res.data.data
})

function search(isFormValid) {
  submitted.value = true

  if (!isFormValid) {
    return
  }

  onExport()
}

function onExport() {
  isLoadingExport.value = true
  const startDate = dayjs(form.date_from).format('YYYY-MM-DD')
  const endDate = dayjs(form.date_to).format('YYYY-MM-DD')
  const pabrikId = form.pabrik

  isLoading.value = true
  laporanService
    .exportLaporanBest(startDate, endDate, pabrikId)
    .then((res) => {
      const blob = new Blob([res.data], { type: 'application/pdf' })
      const pdfUrl = URL.createObjectURL(blob)
      pdfSource.value = pdfUrl + '#toolbar=0'
      pdfFilename.value = 'laporan-barang-best-seller.pdf'
      pdfTitle.value = 'PDF Viewer (' + pdfFilename.value + ')'
      dialogViewer.value = true
    })

    .catch((err) => {
      errorHandler(err, 'Data Laporan Barang Best Seller', toast)
    })
    .finally(() => ((isLoadingExport.value = false), (isLoading.value = false)))
}

function onPrintPdf() {
  const iframe = document.createElement('iframe')
  iframe.src = pdfSource.value
  iframe.style.display = 'none'
  document.body.appendChild(iframe)

  // Use onload to make pdf preview work on firefox
  iframe.onload = () => {
    iframe.contentWindow.focus()
    iframe.contentWindow.print()
  }
}

function onDownloadPdf() {
  saveAs(pdfSource.value, pdfFilename.value)
}

function onToggle() {
  emit('toggle')
}

function resetForm() {
  form.date_from = null
  form.date_to = null
  form.obat = 0
  form.pabrik = 0
}
</script>

<template>
  <fx-report-container
    title="Laporan Barang Best Seller"
    :loading="isLoadingExport"
    @toggle="onToggle"
  >
    <template #content>
      <form @submit.prevent="search(!v$.$invalid)">
        <div class="formgrid grid surface-ground m-1 py-3">
          <div class="field col-12">
            <div class="font-bold">Filter</div>
          </div>
          <div class="field col-12 md:col-4">
            <Dropdown
              v-model="v$.pabrik.$model"
              :options="list_pabrik"
              optionValue="id"
              optionLabel="nama"
              placeholder="Pilih Pabrik"
              class="w-full"
              filter
            />
            <small
              v-if="
                (v$.pabrik.$invalid && submitted) ||
                v$.pabrik.$pending.$response
              "
              class="p-error"
              >{{ v$.pabrik.required.$message }}</small
            >
          </div>
          <div class="field col-12 md:col-2">
            <Calendar
              v-model="v$.date_from.$model"
              dateFormat="dd/mm/yy"
              :class="{
                'p-invalid': v$.date_from.$invalid && submitted,
              }"
              placeholder="Tanggal (dari)"
              class="w-full"
            />
            <small
              v-if="
                (v$.date_from.$invalid && submitted) ||
                v$.date_from.$pending.$response
              "
              class="p-error"
              >{{ v$.date_from.required.$message }}</small
            >
          </div>
          <div class="field col-12 md:col-2">
            <Calendar
              v-model="v$.date_to.$model"
              dateFormat="dd/mm/yy"
              :class="{
                'p-invalid': v$.date_to.$invalid && submitted,
              }"
              placeholder="Tanggal (s/d)"
              class="w-full"
            />
            <small
              v-if="
                (v$.date_to.$invalid && submitted) ||
                v$.date_to.$pending.$response
              "
              class="p-error"
              >{{ v$.date_to.required.$message }}</small
            >
          </div>
          <div class="field col-12 md:col-3">
            <Button type="submit" icon="pi pi-file-pdf" label="Cetak" />
            <Button
              type="reset"
              icon="pi pi-refresh"
              label="Reset"
              class="p-button-outlined ml-2"
              @click="resetForm"
            />
          </div>
        </div>
      </form>
    </template>
  </fx-report-container>
  <Dialog
    :header="pdfTitle"
    v-model:visible="dialogViewer"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '90vw' }"
    :modal="true"
  >
    <div>
      <embed
        :src="pdfSource"
        type="application/pdf"
        width="100%"
        height="600px"
      />
    </div>
    <template #footer>
      <Button
        type="button"
        label="Download"
        icon="pi pi-download"
        class="p-button-text mr-2"
        @click="onDownloadPdf"
      />
      <Button
        type="button"
        label="Print"
        icon="pi pi-print"
        @click="onPrintPdf"
      />
    </template>
  </Dialog>
</template>
